<template>
  <section class="product section-offset">
    <div class="frc-systems-outer">
      <div class="frc-systems" v-if="dataReady">
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Tactile</div>
          <RealForceTactile :classicList="classicList" />
          <div class="system-item__btns">
            <router-link :to="{ name: 'classicAllLevels' }">
              <ButtonDark :text="$t('realForceInfo.btnShow')" classes="frc-systems__button-dark" />
            </router-link>
            <div class="hoverShowLinksPdf" @click.stop>
              <div>
                <p class="tooltip">
                  {{ $t('realForceInfo.btnAbout') }}
                  <svg style="margin-left: var(--indent-8)" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                      stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="square"
                      stroke-linejoin="bevel" />
                  </svg>
                </p>
              </div>
              <div class="languageLink" @click.stop>
                <div class="languageLink-ul">
                  <p v-for="(lang, i) in langs" :key="`Lang${i}`" class="liLang" @click.self="switchLocaleTc(lang)">
                    {{ lang }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Royalty NFT</div>
          <RealForceRoyaltyNft :nftLevels="nftLevels" />
          <div class="system-item__btns">
            <router-link :to="{ name: 'nfts' }">
              <ButtonDark :text="$t('realForceInfo.btnShow')" classes="frc-systems__button-dark" />
            </router-link>
            <div class="hoverShowLinksPdf" @click.stop>
              <div>
                <p class="tooltip">
                  {{ $t('realForceInfo.btnAbout') }}
                  <svg style="margin-left: var(--indent-8)" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                      stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="square"
                      stroke-linejoin="bevel" />
                  </svg>
                </p>
              </div>
              <div class="languageLink" @click.stop>
                <div class="languageLink-ul">
                  <p v-for="(lang, i) in langs" :key="`Lang${i}`" class="liLang" @click.self="switchLocaleRl(lang)">
                    {{ lang }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="frc-systems__item system-item">
          <div class="system-item__title">Uniteverse</div>
          <div class="system-item__levels system-united">
            <div v-for="i in 9" :key="i" class="system-item__level" :class="isUvLevelActive(i) ? 'active' : 'notActive'">
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="56" height="56" rx="28" fill="black" />
                <circle v-if="isUvLevelActive(i)" cx="28" cy="29" r="25" stroke="white" stroke-opacity="0.2"
                  stroke-width="3" stroke-dasharray="1 1" />
                <path v-if="isUvLevelActive(i)"
                  d="M6.34936 16.5C3.59769 21.266 2.49555 26.8069 3.21388 32.2632C3.93221 37.7194 6.43087 42.7862 10.3223 46.6777C14.2138 50.5691 19.2806 53.0678 24.7368 53.7861C30.1931 54.5045 35.734 53.4023 40.5 50.6506C45.266 47.899 48.9909 43.6515 51.097 38.5671C53.203 33.4826 53.5725 27.8454 52.1481 22.5295C50.7238 17.2137 47.5851 12.5164 43.219 9.16617C38.8529 5.81594 33.5034 4 28 4"
                  stroke="#FF9501" stroke-width="3" stroke-dasharray="1 1" />
              </svg>
              <p>{{ i }}</p>
            </div>
          </div>
          <div class="system-item__btns">
            <router-link :to="{ name: 'uniteverse' }">
              <ButtonDark :text="$t('realForceInfo.btnShow')" classes="frc-systems__button-dark" />
            </router-link>
            <div class="hoverShowLinksPdf" @click.stop>
              <div>
                <p class="tooltip">
                  {{ $t('realForceInfo.btnAbout') }}

                  <svg style="margin-left: var(--indent-8)" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                      stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="square"
                      stroke-linejoin="bevel" />
                  </svg>
                </p>
              </div>
              <div class="languageLink" @click.stop>
                <div class="languageLink-ul">
                  <p v-for="(lang, i) in langsUv" :key="`Lang${i}`" class="liLang" @click.self="switchLocaleUv(lang)">
                    {{ lang }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Tactile Goods</div>
          <div class="system-item__levels system-nft_classic"></div>
          <div class="system-item__btns">
            <ButtonDark :text="$t('realForceInfo.btnSoon')" classes="frc-systems__button-dark" :disabled="true" />
            <p class="tooltip">
              {{ $t('realForceInfo.btnAbout') }}
              <svg style="margin-left: var(--indent-8)" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="square" stroke-linejoin="bevel" />
              </svg>
            </p>
          </div>
        </div>
        <div class="frc-systems__item system-item">
          <div class="system-item__title">Metaverse</div>
          <div class="system-item__levels system-metaverse"></div>
          <div class="system-item__btns">
            <ButtonDark :text="$t('realForceInfo.btnSoon')" classes="frc-systems__button-dark" :disabled="true" />
            <p class="tooltip">
              {{ $t('realForceInfo.btnAbout') }}
              <svg style="margin-left: var(--indent-8)" width="20" height="20" viewBox="0 0 20 20" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.375 9.375H10V13.75H10.625M10 6V6.2M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                  stroke="white" stroke-opacity="0.3" stroke-width="2" stroke-linecap="square" stroke-linejoin="bevel" />
              </svg>
            </p>
          </div>
        </div>
      </div>

      <UiLoader v-else theme="block" :loading="true" />
    </div>

    <!-- The content before the redesign is here -->

    <!-- <UiLoader v-if="!dataReady" theme="block" :loading="true" /> -->

    <!-- <div
      class="tabs-offset matrix-grid"
      v-if="activeTab === 'classic' && dataReady"
    >
      <MatrixCard
        v-for="card in classicMatrix"
        :key="card.lvl"
        :card="card"
        :account="account"
      />
    </div> -->

    <!-- <MatrixReferralTree
      v-if="activeTab === 'systems_referral' && dataReady"
      :user="userData"
    /> -->

  </section>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

import pageReadyByConnect from '@/mixin/pageReadyByConnect'
import nftsApi from "@/api/nftsApi"
import RealForceTactile from "@/components/Dashboard/RealForceTactile.vue"
import RealForceRoyaltyNft from "@/components/Dashboard/RealForceRoyaltyNft.vue"
// import MatrixCard from '@/components/Matrix/Card.vue'
// import MatrixReferralTree from '@/components/Matrix/ReferralTree.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'

export default {
  data() {
    return {
      // id: null,
      account: null,
      userData: null,
      nfts: [],
      // unitedVerse: false,
      langsUv: ['EN', 'ES', 'VI', 'RU'],
      langs: ['EN', 'RU'],
      uvLevel: 0
    }
  },
  components: {
    // MatrixCard,
    // MatrixReferralTree,
    RealForceTactile,
    RealForceRoyaltyNft,
    ButtonDark,
  },
  mixins: [pageReadyByConnect],
  async mounted() {
    if (this.isConnected) {
      await this.pageReady()
      await this.loadNfts()
    }
  },
  computed: {
    ...mapState('user', ['classicMatrix']),
    ...mapGetters('user', ['totalActiveLevels']),
    ...mapGetters('crypto', ['getAccount', 'getNftLvl']),

    dataReady() {
      return this.account && this.classicMatrix && this.classicMatrix.length
    },

    classicList() {
      return this.classicMatrix
    },

    nftLevels() {
      let lvls = []
      for (let nft of this.nfts) {
        lvls.push(this.getNftLvl(nft.contractAddress))
      }
      return lvls
    },
  },
  methods: {
    ...mapActions('crypto', ['getProgramLevels', 'checkRegistration', 'getUVLevel']),
    ...mapActions('user', ['guessUser']),
    ...mapMutations('user', ['setClassicMatrix', 'updateClassicMatrixLevels']),
    isUvLevelActive(level) {
      return level <= this.uvLevel;
    },
    switchLocaleTc(locale) {
      switch (locale) {
        case 'EN':
          window.open('./assets/files/pdf/Tactile_en.pdf', '_blank');
          break;
        case 'RU':
          window.open('./assets/files/pdf/Tactile_ru.pdf', '_blank');
          break;
      }
    },
    switchLocaleRl(locale) {
      switch (locale) {
        case 'EN':
          window.open('./assets/files/pdf/Royalty_NFT_EN.pdf', '_blank');
          break;
        case 'RU':
          window.open('./assets/files/pdf/Royalty_NFT_RU.pdf', '_blank');
          break;
      }
    },
    switchLocaleUv(locale) {
      switch (locale) {
        case 'EN':
          window.open('./assets/files/pdf/Uniteverse_en.pdf', '_blank');
          break;
        case 'ES':
          window.open('./assets/files/pdf/Uniteverse_es.pdf', '_blank');
          break;
        case 'VI':
          window.open('./assets/files/pdf/Uniteverse_vi.pdf', '_blank');
          break;
        case 'RU':
          window.open('./assets/files/pdf/Uniteverse_ru.pdf', '_blank');
          break;
      }
    },
    async pageReady() {
      const [regErr, regParent] = await this.checkRegistration(true)
      if (regErr) {
        this.$swal(this.$t('errors.registrationError'))
        await this.$router.push({ name: 'signup' })
        return
      }

      const accountQuery = this.$route.query.account
      const targetAccount = accountQuery || this.getAccount


      try {
        let userResponse = await this.guessUser(targetAccount)
        if (!userResponse) throw new Error()

        this.id = userResponse.name
        this.account = userResponse.account
        this.userData = userResponse


        this.setClassicMatrix(userResponse.classic)

        // this.$toast.info(userResponse.classic)

        const [err, levels] = await this.getProgramLevels(userResponse.account)

        if (err) {
          this.$toast.error(`Fetch program level from blockchain error: ${err}`)
        }

        this.updateClassicMatrixLevels(levels)

        this.uvLevel = await this.getUVLevel(targetAccount);
      } catch (err) {
        this.$swal(this.$t('errors.userNotFound'))
        await this.$router.push({ name: 'dashboard' })
      }
    },
    // async loadNfts() {
    //   await nftsApi
    //       .nfts({
    //           owner: this.getAccount,
    //       })
    //       .then(({ data }) => {
    //           this.nfts = data
    //           console.log('NFT REAL FORCE: --------------------',nfts)
    //       })
    //       .catch((err) => {
    //           console.log("err", err)
    //       })
    // }
    async loadNfts() {
      let targetAccount
      const accountQuery = this.$route.query.account
      if (accountQuery) {
        targetAccount = accountQuery
      } else {
        targetAccount = this.getAccount
      }
      const userAcc = await this.guessUser(targetAccount)
      await nftsApi
        .nfts({
          owner: userAcc.account.toLowerCase(),
        })
        .then(({ data }) => {
          this.nfts = data
        })
        .catch((err) => {
          console.log("err", err)
        })
    },

  },

}
</script>

<style lang="scss">
.frc-systems__button-dark {
  background: var(--color-white-10);
  min-width: 174px;
  min-height: 48px;
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  font-weight: var(--font-600);
  color: var(--color-white);
  margin-bottom: var(--indent-14);
  transition: background 500ms;

  &:hover {
    background: var(--color-white-30);
  }
}

.hoverShowLinksPdf {
  &:hover {
    .languageLink {
      opacity: 1;
    }
  }

  .languageLink {
    position: absolute;
    z-index: 10;
    top: 100%;
    left: 18%;
    width: max-content;
    opacity: 0;
    transition: .2s all ease-in;

    &-ul {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100px;
    }

    .liLang {
      transition: .2s all ease-in;
      color: var(--color-white-50);

      &:hover {
        color: var(--color-orange-900);
        cursor: pointer;
      }
    }

    @media (max-width: 991px) {
      top: 67%;
      left: 60%;
    }

    @media (max-width: 500px) {
      margin-left: 15px;

      &-ul {
        gap: 4px;
      }
    }
  }
}
</style>
